import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [


  {
    path:'/',
    component:()=>import('@/views/LuckyRunning.vue')
  },
  {
    path:'/wx',
    component:()=>import('@/views/wx.vue')
  }
 
]
const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
    const query = to.query;
    const isWeChat = window.navigator.userAgent.indexOf('MicroMessenger') > -1;
    if (isWeChat) {
      if(!!query.id) {
        if(to.path == '/wx') {
          next();
        } else {
          next({ path: '/wx', query });
        }
      } else {
        next();
      }
    } else {
      if (!!query.id) {
        if(to.path == '/wx') {
          next({ path: '/', query });
        } else {
          next();
        }
      } else {
        next();
      }
    }
});

export default router
