import Vue from 'vue'
import App from './App.vue'
import router from './router'
import * as echarts from 'echarts'
import {
  buttonPermissions
} from '@/untils/permissions'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'animate.css';

// 滚动动画 wow.js
import {
  WOW
} from 'wowjs'

Vue.prototype.$wow = new WOW({
  boxClass: 'wow', // default
  animateClass: 'animated', // default
  offset: 50, // default
  mobile: true, // default
  live: false,
 
  // live为true时，控制台会提示：MutationObserver is not supported by your browser. & WOW.js cannot detect dom mutations, please call .sync() after loading new content.
 
  callback: function (box) {
    console.log("WOW: animating <" + box.tagName.toLowerCase() + ">")
  }
})
Vue.config.productionTip = false
Vue.prototype.$echarts = echarts
Vue.use(ElementUI);
new Vue({
  router,
  buttonPermissions,
  render: h => h(App)
}).$mount('#app')